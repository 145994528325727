import Vue from "vue";
import VueRouter from "vue-router";
import demoRoutes from "./demo/index";
import layoutRoutes from "./layout/index";
import adminRoutes from "./admin/index";
import storage from "../utils/storage";
import store from "@/store/index";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/layout/IndexPage.vue"),
    children: [...layoutRoutes],
  },
  {
    path: "/admin",
    name: "adminIndex",
    component: () =>
      import(
        /* webpackChunkName: "adminIndex" */ "@/views/admin/IndexPage.vue"
      ),
    children: [...adminRoutes],
  },
  {
    path: "/login",
    name: "login",
    title: "登录页面",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginPage.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () =>
      import(/* webpackChunkName: "demo" */ "@/views/demo/DemoIndex.vue"),
    children: [...demoRoutes],
  },
  {
    path: "*",
    name: "404",
    title: "空页面",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const pathArr = to.path.split("/");
  const layoutPath = ["", "company", "product", "news", "contact"];

  if (to.path.indexOf("/admin") == 0) {
    const userPwd = storage.getItem("userPwd");
    if (!userPwd) {
      alert("请登录");
      next({ path: "/login" });
    } else {
      next();
    }
  } else if (
    (pathArr[1] || pathArr[1] == "") &&
    layoutPath.includes(pathArr[1])
  ) {
    const str = pathArr.slice(0, 2).join("/");
    store.commit("appStore/updateCurrentMenuPath", str);
    next();
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
