import Vue from "vue";
import App from "./App.vue";

import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import filters from "./filters/filters";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/style/reset.css";
import "@/assets/style/element-ui.scss";
import "@/assets/style/global.scss";

// import animate from "animate.css";

import VueI18n from "vue-i18n";

Vue.use(ElementUI);
// Vue.use(animate);
Vue.use(VueI18n);

// filter里面的方法 同时挂载到vue实例 两种使用方式
Vue.prototype.$filters = filters;
for (const key in filters) {
  Vue.filter(key, filters[key]);
}

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: "cn", // 默认语言
  fallbackLocale: "cn", // 如果当前语言不存在对应翻译则使用默认语言
  messages: {
    en: require("@/locales/en.json"),
    cn: require("@/locales/cn.json"),
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
