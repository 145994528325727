export default [
  {
    path: "/admin",
    name: "productMgt",
    component: () =>
      import(
        /* webpackChunkName: "productMgt" */ "@/views/admin/ProductManage.vue"
      ),
  },
  {
    path: "/admin/news",
    name: "newsMgt",
    component: () =>
      import(/* webpackChunkName: "newsMgt" */ "@/views/admin/NewsManage.vue"),
  },
  {
    path: "/admin/contact",
    name: "contactMgt",
    component: () =>
      import(
        /* webpackChunkName: "contactMgt" */ "@/views/admin/ContactManage.vue"
      ),
  },
];
