export default [
  {
    path: "/demo",
    name: "demo",
    component: () =>
      import(/* webpackChunkName: "demo" */ "@/views/demo/DemoIndex.vue"),
    children: [
      {
        path: "/demo/demo1",
        name: "demo1",
        component: () =>
          import(
            /* webpackChunkName: "demo1" */ "@/views/demo/demo1/demo1.vue"
          ),
      },
      {
        path: "/demo/demo2",
        name: "demo2",
        component: () =>
          import(
            /* webpackChunkName: "demo2" */ "@/views/demo/demo2/demo2.vue"
          ),
      },
      {
        path: "/demo/demo3",
        name: "demo3",
        component: () =>
          import(/* webpackChunkName: "demo3" */ "@/views/demo/demo3/demo3"),
      },
      {
        path: "/demo/demo4",
        name: "demo4",
        component: () =>
          import(
            /* webpackChunkName: "demo4" */ "@/views/demo/demo4/richEditor.vue"
          ),
      },
    ],
  },
];
