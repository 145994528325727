export default [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/layout/HomePage.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "@/views/layout/CompanyPage.vue"
      ),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import(
        /* webpackChunkName: "product" */ "@/views/layout/ProductPage.vue"
      ),
  },
  {
    path: "/product/detail/:id",
    name: "productDetail",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/layout/ProductDetailPage.vue"
      ),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/layout/NewsPage.vue"),
  },
  {
    path: "/news/detail/:id",
    name: "newsDetail",
    component: () =>
      import(
        /* webpackChunkName: "newsDetail" */ "@/views/layout/NewsDetailPage.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "@/views/layout/ContactPage.vue"
      ),
  },
];
