const filters = {
  // table表格中空格或空数据过滤
  emptyFormat: function (value) {
    if (value || value === 0) {
      return value;
    } else {
      return "--";
    }
  },

  // 千分位过滤器
  formatMoney: function (
    number,
    decimals = 2,
    decPoint = ".",
    thousandsSep = ","
  ) {
    number = (number + "").replace(/[^0-9+-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
    let dec = typeof decPoint === "undefined" ? "." : decPoint;
    let s = "";
    let toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  },

  // 保留两位小数
  toDecimalTwo: function (x) {
    var f_x = parseFloat(x);
    if (isNaN(f_x)) {
      return 0;
    }
    f_x = Math.round(x * 100) / 100;
    var s_x = f_x.toString();
    var pos_decimal = s_x.indexOf(".");
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += ".";
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += "0";
    }
    return s_x;
  },

  // 时间戳/时间对象转时间格式 yyyy-MM-dd hh:ss:mm
  formatDate: function (date, fmt) {
    if (!date) {
      return "";
    }
    if (typeof date === "number") {
      // 时间戳转化为时间对象
      date = new Date(date);
    }
    let o = {
      "M+": date.getMonth() + 1, // 月份
      "d+": date.getDate(), // 日
      "h+": date.getHours(), // 小时
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    }
    return fmt;
  },
};
export default filters;
